import config from '@ir-engine/common/src/config'
import React, { useEffect } from 'react'

export const Redirect = () => {
  useEffect(() => {
    const currentPath = window.location.pathname
    const searchParams = new URLSearchParams(window.location.search)
    let path = ''

    if (searchParams.has('error')) {
      path = `/welcome?dashboard=concierge&error=${searchParams.get('error')}`
    } else if (currentPath === '/concierge') {
      path = '/welcome?dashboard=concierge'
    } else if (currentPath === '/concierge/signin') {
      path = '/signin?dashboard=concierge'
    } else if (currentPath === '/concierge/signup') {
      path = '/signup?dashboard=concierge'
    } else {
      path = '/concierge/dashboard'
    }

    const url = `${config.client.clientUrl}${path}`
    window.location.href = url
  }, [])

  return <></>
}

export default Redirect
