export * from '@ir-engine/common/src/schema.type.module'
export * from '@theinfinitereality/irpro-stripe/services/schema.type.module'

export type * from './account/account-project-setting/account-project-setting.schema'
export type * from './account/account-project/account-project.schema'
export type * from './account/account-slug-generator/account-slug-generator.schema'
export type * from './account/account/account.schema'
export type * from './customer/customer.schema'
export type * from './domain-provision/domain-provision.schema'
export type * from './domain/domain.schema'
export type * from './entri/certificate-verification/certificate-verification.schema'
export type * from './entri/distribution-verification/distribution-verification.schema'
export type * from './entri/dns-verification/dns-verification.schema'
export type * from './entri/entri-auth/entri-auth.schema'
export type * from './invite-customer/invite-customer.schema'

export const accountPath = 'account'

export const accountProjectPath = 'account-project'

export const accountSlugGeneratorPath = 'account-slug-generator'

export const accountProjectSettingPath = 'account-project-setting'

export const customerPath = 'customer'

export const domainPath = 'domain'

export const domainProvisionPath = 'domain-provision'

export const entriAuthPath = 'entri-auth'

export const dnsVerificationPath = 'dns-verification'

export const certificateVerificationPath = 'certificate-verification'

export const distributionVerificationPath = 'distribution-verification'

export const inviteCustomerPath = 'invite-customer'
