import type { ProjectConfigInterface } from '@ir-engine/projects/ProjectConfigInterface'

const config: ProjectConfigInterface = {
  onEvent: './projectEventHooks.ts',
  thumbnail: '/static/ir-engine.png',
  routes: {},
  services: './services/services.ts',
  databaseSeed: undefined
}

export default config
