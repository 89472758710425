import type { ProjectConfigInterface } from '@ir-engine/projects/ProjectConfigInterface'

const config: ProjectConfigInterface = {
  onEvent: './projectEventHooks.ts',
  thumbnail: '/static/ir-engine.png',
  webappInjection: () => import('./webappInjection'),
  routes: {
    '/concierge': {
      component: () => import('./components/pages/Redirect'),
      props: {
        exact: true
      }
    },
    '/concierge/welcome': {
      component: () => import('./components/pages/ConciergeWelcomePage'),
      props: {
        exact: true
      }
    },
    '/concierge/create': {
      component: () => import('./components/pages/ConciergeCreate/ConciergeCreatePage'),
      props: {
        exact: true
      }
    },
    '/concierge/dashboard': {
      component: () => import('./components/pages/Dashboard/Dashboard'),
      props: {
        exact: true
      }
    },
    '/concierge/bot': {
      component: () => import('./components/pages/Bot/BotPage'),
      props: {
        exact: true
      }
    }
  },
  services: './services/services.ts',
  databaseSeed: './services/seeder-config.ts'
}

export default config
